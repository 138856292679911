
import { isEqual } from "lodash";
import IndeedBlueIcon from "../../app/components/svgIcons/IndeedBlueIcon";
import LinkedInImageIcon from "../../app/components/svgIcons/LinkedInImageIcon";
import PostingDetailIcon from "../../app/components/svgIcons/PostingDetailIcon";
import SummaryIcon from "../../app/components/svgIcons/SummaryIcon";

export const sort = [
  {
    id: 1,
    label: 'None',
    value: 'createdAt',
    dir: 'DESC',
    match: "checked1"
  },
  {
    id: 2,
    label: 'Name - A to Z',
    value: 'campaignName',
    dir: 'ASC',
    match: "checked2"
  },
  {
    id: 3,
    label: 'Name - Z to A',
    value: 'campaignName',
    dir: 'DESC',
    match: "checked3"
  },
  {
    id: 4,
    label: 'Start Date - Oldest to Latest',
    value: 'startDate',
    dir: 'ASC',
    match: "checked4"
  },
  {
    id: 5,
    label: 'Start Date - Latest to Oldest',
    value: 'startDate',
    dir: 'DESC',
    match: "checked5"
  },

];

export const campaignStatus = [
  {
    label: 'PENDING',
    value: 1,
  }, {
    label: 'LIVE',
    value: 2,
  }, {
    label: 'CLOSED',
    value: 21,
  },
  {
    label: 'SCHEDULED',
    value: 63,
  },
  {
    label: 'SUSPENDED',
    value: 3,
  },
  {
    label: 'DRAFT',
    value: 6,
  },
  {
    label: 'FAILED',
    value: 19,
  }
];

export const Jobsort = [
  {
    id: 1,
    label: 'None',
    value: 'createdAt',
    dir: 'DESC',
    match: "checked1"
  },
  {
    id: 2,
    label: 'Created (Oldest to Newest)',
    value: 'createdAt',
    dir: 'ASC',
    match: "checked2"
  },
  {
    id: 3,
    label: 'Created (Newest to Oldest)',
    value: 'createdAt',
    dir: 'DESC',
    match: "checked3"
  },
  {
    id: 4,
    label: 'Name (A to Z)',
    value: 'title',
    dir: 'ASC',
    match: "checked4"
  },
  {
    id: 5,
    label: 'Name (Z to A)',
    value: 'title',
    dir: 'DESC',
    match: "checked5"
  },
];
export const sidebar = [
  {
    title: 'Posting Details',
    number: 1,
    icon: <PostingDetailIcon className={'me-2'} size={18} />,
  },
  {
    title: 'Summary',
    number: 2,
    icon: <SummaryIcon className={'me-2'} size={18} />,
  },

];
export const jobBoardsArray = [
  { id: 1, icon: <LinkedInImageIcon />, name: "LinkedIn", cost: "£15", accesskey: "11" },
  { id: 1, icon: <LinkedInImageIcon />, name: "Monster", cost: "£15", accesskey: "12" },
  { id: 1, icon: <LinkedInImageIcon />, name: "Monster", cost: "£15", accesskey: "13" },
  { id: 1, icon: <LinkedInImageIcon />, name: "Reed", cost: "£15", accesskey: "16" },
  { id: 1, icon: <LinkedInImageIcon />, name: "Monster", cost: "£15", accesskey: "18" },
  { id: 1, icon: <LinkedInImageIcon />, name: "Monster", cost: "£15", accesskey: "19" },
  { id: 1, icon: <LinkedInImageIcon />, name: "indeed", cost: "£15", accesskey: "12" },
  { id: 1, icon: <LinkedInImageIcon />, name: "Monster", cost: "£15", accesskey: "12" }
];

export const salaryRates = [
  {
      label: 'Annual Salary',
      value: 'Annual Salary',
  },
  {
      label: 'Daily Rate',
      value: 'Daily Rate',
  },
  {
    label: 'Hourly Rate',
    value: 'Hourly Rate',
}

];
export const jobBoardAccessKeys = {
  CV_LIBRARY: "OCCY_CV_LIBRARY",
  REED: "OCCY_REED",
  NHS: "OCCY_NHS",
  MONSTER: "OCCY_MONSTER",
  TOTAL_JOBS: "OCCY_TOTAL_JOBS",
  GUM_TREE: "OCCY_GUM_TREE",
}

export const jobBoardAccessKe = {
  CV_LIBRARY: "CV_LIBRARY",
  REED: "REED",
  NHS: "NHS",
  MONSTOR: "MONSTER",
  TOTAL_JOBS: "TOTAL_JOBS",
  GUM_TREE: "GUM_TREE",
}
export const advertKeys = {
  FAILED: 0,
  PENDING: 1,
  ACTIVE: 2,
  REJECTED: 3,
  DELETED: 4,
}
export const industries = [
  { label: 'Accounting/Financial/Insurance', value: 'Accounting/Financial/Insurance' },
  { label: 'Administration', value: 'Administration' },
  { label: 'Agriculture', value: 'Agriculture' },
  { label: 'Arts/Graphic Design', value: 'Arts/Graphic Design' },
  { label: 'Automotive/Aerospace', value: 'Automotive/Aerospace' },
  { label: 'Catering', value: 'Catering' },
  { label: 'Charity', value: 'Charity' },
  { label: 'Construction', value: 'Construction' },
  { label: 'Consulting', value: 'Consulting' },
  { label: 'Customer Services', value: 'Customer Services' },
  { label: 'Distribution', value: 'Distribution' },
  { label: 'Education', value: 'Education' },
  { label: 'Electronics', value: 'Electronics' },
  { label: 'Engineering', value: 'Engineering' },
  { label: 'Hospitality/Hotel', value: 'Hospitality/Hotel' },
  { label: 'IT', value: 'IT' },
  { label: 'Legal', value: 'Legal' },
  { label: 'Leisure/Tourism', value: 'Leisure/Tourism' },
  { label: 'Management', value: 'Management' },
  { label: 'Manufacturing/Surveying', value: 'Manufacturing/Surveying' },
  { label: 'Marketing', value: 'Marketing' },
  { label: 'Media', value: 'Media' },
  { label: 'Medical/Pharmaceutical/Scientific', value: 'Medical/Pharmaceutical/Scientific' },
  { label: 'Military/Emergency/Government', value: 'Military/Emergency/Government' },
  { label: 'Personnel/Recruitment', value: 'Personnel/Recruitment' },
  { label: 'Property Services', value: 'Property Services' },
  { label: 'Public Sector', value: 'Public Sector' },
  { label: 'Retail/Purchasing', value: 'Retail/Purchasing' },
  { label: 'Sales', value: 'Sales' },
  { label: 'Social Care', value: 'Social Care' },
  { label: 'Telecoms', value: 'Telecoms' },
  { label: 'Other', value: 'Other' },
];
export const quickapply = [
  { label: 'Yes', value: true },
  { label: 'No', value: false }];

  export const coverLetter = [
    { label: 'Optional', value: 'OPTIONAL' },
    { label: 'Required', value: 'REQUIRED' },
    { label: 'Not Required', value: 'NOT_REQUIRED' },
  ];
export const CLICKADVERTPRODUCTICONS = {

  'INDEED_SPONSORED': <IndeedBlueIcon width={44} size={40} />
}

export const PostingSortItem = [
  {
    id: 1,
    label: 'None',
    value: 'requestedOn',
    dir: 'DESC',
    match: "checked1"
  },
  {
    id: 2,
    label: 'Requested on (Oldest to Newest)',
    value: 'requestedOn',
    dir: 'ASC',
    match: "checked2"
  },
  {
    id: 3,
    label: 'Requested (Newest to Oldest)',
    value: 'requestedOn',
    dir: 'DESC',
    match: "checked3"
  },
];

export const productTypes = [
  {
    label: 'Standard job',
    value: 'Standard job',
  },
  {
    label: 'Premium job',
    value: 'Premium job',
  },
  {
    label: 'Featured job',
    value: 'Featured job',
  },
  {
    label: 'Premium + job',
    value: 'Premium + job',
  },
  {
    label: 'Marketplace job',
    value: 'Marketplace job',
  }

];

export const parentSector = [
  {
    label: 'Accountancy',
    value: 'Accountancy',
  },
  {
    label: 'Accountancy (Qualified)',
    value: 'Accountancy (Qualified)',
  },
  {
    label: 'Admin, Secretarial & PA',
    value: 'Admin, Secretarial & PA',
  },
  {
    label: 'Apprenticeships',
    value: 'Apprenticeships',
  },
  {
    label: 'Banking',
    value: 'Banking',
  },
  {
    label: 'Charity & Voluntary',
    value: 'Charity & Voluntary',
  },
  {
    label: 'Construction & Property',
    value: 'Construction & Property',
  },
  {
    label: 'Customer Service',
    value: 'Customer Service',
  },
  {
    label: 'Education',
    value: 'Education',
  },
  {
    label: 'Energy',
    value: 'Energy',
  },
  {
    label: 'Engineering',
    value: 'Engineering',
  },
  {
    label: 'Estate Agency',
    value: 'Estate Agency',
  },
  {
    label: 'Financial Services',
    value: 'Financial Services',
  },
  {
    label: 'FMCG',
    value: 'FMCG',
  },
  {
    label: 'General Insurance',
    value: 'General Insurance',
  },
  {
    label: 'Graduate Roles',
    value: 'Graduate Roles',
  },
  {
    label: 'Health & Medicine',
    value: 'Health & Medicine',
  },
  {
    label: 'Hospitality & Catering',
    value: 'Hospitality & Catering',
  },
  {
    label: 'Human Resources',
    value: 'Human Resources',
  },
  {
    label: 'IT & Telecoms',
    value: 'IT & Telecoms',
  },
  {
    label: 'Legal',
    value: 'Legal',
  },
  {
    label: 'Leisure & Tourism',
    value: 'Leisure & Tourism',
  },
  {
    label: 'Manufacturing',
    value: 'Manufacturing',
  },
  {
    label: 'Marketing & PR',
    value: 'Marketing & PR',
  },
  {
    label: 'Media, Digital & Creative',
    value: 'Media, Digital & Creative',
  },
  {
    label: 'Motoring & Automotive',
    value: 'Motoring & Automotive',
  },
  {
    label: 'Purchasing',
    value: 'Purchasing',
  },
  {
    label: 'Recruitment Consultancy',
    value: 'Recruitment Consultancy',
  },
  {
    label: 'Retail',
    value: 'Retail',
  },
  {
    label: 'Sales',
    value: 'Sales',
  },
  {
    label: 'Scientific',
    value: 'Scientific',
  },
  {
    label: 'Security & Safety',
    value: 'Security & Safety',
  },
  {
    label: 'Social Care',
    value: 'Social Care',
  },
  {
    label: 'Strategy & Consultancy',
    value: 'Strategy & Consultancy',
  },
  {
    label: 'Transport & Logistics',
    value: 'Transport & Logistics',
  },
];

export const inventoryType = [
  { label: 'Transactional', value: 'Transactional' },
  { label: 'Slotted', value: 'Slotted' },
  { label: 'AreaWideTransactional', value: 'AreaWideTransactional' },
  { label: 'AreaWideSlotted', value: 'AreaWideSlotted' },
];

export const jobLevel = [
  { label: 'Student (High School)', value: 'Student (High School)' },
  { label: 'Student (Undergraduate/Graduate)', value: 'Student (Undergraduate/Graduate)' },
  { label: 'Entry Level', value: 'Entry Level' },
  { label: 'Experienced (Non-Manager)', value: 'Experienced (Non-Manager)' },
  { label: 'Manager (Manager/Supervisor of Staff)', value: 'Manager (Manager/Supervisor of Staff)' },
  { label: 'Executive (SVP, VP, Department Head, etc)', value: 'Executive (SVP, VP, Department Head, etc)' },
  { label: 'Senior Executive (President, CFO, etc)', value: 'Senior Executive (President, CFO, etc)' },
];


export const jobType = [
  { label: 'Employee', value: 'Employee' },
  { label: 'Temporary / Contract', value: 'Temporary / Contract' },
  { label: 'Intern', value: 'Intern' },
  { label: 'Seasonal', value: 'Seasonal' },
  { label: 'Volunteer', value: 'Volunteer' },
  { label: 'CCL Sponsored', value: 'CCL Sponsored' },
  { label: 'Temporary', value: 'Temporary' },
];

export const monsterDuration = [
  { label: '30 days', value: 30 },
  { label: '60 days', value: 60 },
]

export const industryType = [
  { label: "All", value: "All" },
  { label: "Agriculture/Forestry/Fishing", value: "Agriculture/Forestry/Fishing" },
  { label: "Metals and Minerals", value: "Metals and Minerals" },
  { label: "Energy and Utilities", value: "Energy and Utilities" },
  { label: "Construction - Industrial Facilities and Infrastructure", value: "Construction - Industrial Facilities and Infrastructure" },
  { label: "Aerospace and Defense", value: "Aerospace and Defense" },
  { label: "Automotive and Parts Mfg", value: "Automotive and Parts Mfg" },
  { label: "Biotechnology/Pharmaceuticals", value: "Biotechnology/Pharmaceuticals" },
  { label: "Chemicals/Petro-Chemicals", value: "Chemicals/Petro-Chemicals" },
  { label: "Consumer Packaged Goods Manufacturing", value: "Consumer Packaged Goods Manufacturing" },
  { label: "Electronics, Components, and Semiconductor Mfg", value: "Electronics, Components, and Semiconductor Mfg" },
  { label: "Manufacturing - Other", value: "Manufacturing - Other" },
  { label: "Printing and Publishing", value: "Printing and Publishing" },
  { label: "Clothing and Textile Manufacturing", value: "Clothing and Textile Manufacturing" },
  { label: "Wholesale Trade/Import-Export", value: "Wholesale Trade/Import-Export" },
  { label: "Retail", value: "Retail" },
  { label: "Travel, Transportation and Tourism", value: "Travel, Transportation and Tourism" },
  { label: "Transport and Storage - Materials", value: "Transport and Storage - Materials" },
  { label: "Internet Services", value: "Internet Services" },
  { label: "Broadcasting, Music, and Film", value: "Broadcasting, Music, and Film" },
  { label: "Telecommunications Services", value: "Telecommunications Services" },
  { label: "Banking", value: "Banking" },
  { label: "Insurance", value: "Insurance" },
  { label: "Real Estate/Property Management", value: "Real Estate/Property Management" },
  { label: "Rental Services", value: "Rental Services" },
  { label: "Accounting and Auditing Services", value: "Accounting and Auditing Services" },
  { label: "Advertising and PR Services", value: "Advertising and PR Services" },
  { label: "Architectural and Design Services", value: "Architectural and Design Services" },
  { label: "Management Consulting Services", value: "Management Consulting Services" },
  { label: "Computer Hardware", value: "Computer Hardware" },
  { label: "Computer Software", value: "Computer Software" },
  { label: "Legal Services", value: "Legal Services" },
  { label: "Waste Management", value: "Waste Management" },
  { label: "Education", value: "Education" },
  { label: "Healthcare Services", value: "Healthcare Services" },
  { label: "Performing and Fine Arts", value: "Performing and Fine Arts" },
  { label: "Sports and Physical Recreation", value: "Sports and Physical Recreation" },
  { label: "Hotels and Lodging", value: "Hotels and Lodging" },
  { label: "Restaurant/Food Services", value: "Restaurant/Food Services" },
  { label: "Staffing/Employment Agencies", value: "Staffing/Employment Agencies" },
  { label: "Nonprofit Charitable Organizations", value: "Nonprofit Charitable Organizations" },
  { label: "Personal and Household Services", value: "Personal and Household Services" },
  { label: "Government and Military", value: "Government and Military" },
  { label: "Security and Surveillance", value: "Security and Surveillance" },
  { label: "Automotive Sales and Repair Services", value: "Automotive Sales and Repair Services" },
  { label: "Business Services - Other", value: "Business Services - Other" },
  { label: "Computer/IT Services", value: "Computer/IT Services" },
  { label: "Construction - Residential & Commercial/Office", value: "Construction - Residential & Commercial/Office" },
  { label: "Engineering Services", value: "Engineering Services" },
  { label: "Entertainment Venues and Theaters", value: "Entertainment Venues and Theaters" },
  { label: "Financial Services", value: "Financial Services" },
  { label: "Food and Beverage Production", value: "Food and Beverage Production" },
  { label: "Marine Mfg & Services", value: "Marine Mfg & Services" },
  { label: "Medical Devices and Supplies", value: "Medical Devices and Supplies" },
  { label: "Other/Not Classified", value: "Other/Not Classified" }
];

export const postingError = (jobBoard, failedResp) => {
  if (!failedResp?.length) return false;

  const errorMessage = failedResp.find(item => item?.accessKey === jobBoard);
  if (!errorMessage) return false;

  return errorMessage?.postingDetails?.data?.message || JSON.stringify(errorMessage?.postingDetails?.data);
};

