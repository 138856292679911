import React, { useEffect } from 'react';
import { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { StyledLabel } from '../CampaignDetails';
import PortletHeader from '../../../app/components/portlet/PortletHeader';
import Portlet from '../../../app/components/portlet/Portlet';
import TabsSidebar from '../../../app/components/styledComponents/TabsSidebar';
import { jobBoardAccessKeys, monsterDuration, sidebar } from '../CampaignsUtils';
import { Wrap } from '../../../assets/styledComponents/Global';
import AdvertPostingDetails from './AdvertPostingDetails';
import JobBoardSummary from './JobBoardSummary';
import { useAdvertPostingMutation, useGetCampaignPostingDetailsQuery, useGetJobBoardSummaryQuery, useGetPostingDetailsQuery } from '../CampainApiSlice';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Form, Formik } from 'formik';


const CampaignPostingDetailPage = () => {
  const { campaignId, postingId } = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const [failedResp, setFailedResp] = useState();
  const location = useLocation();
  const status = location.state;
  const { data : campaignPosting, refetch : campaginRefetch } = useGetCampaignPostingDetailsQuery({
    postingIds: postingId,
  },{
    skip : !postingId,
    refetchOnMountOrArgChange:true,
  });
  const jobBoardKeys = campaignPosting && campaignPosting[0]?.jobPostings?.product?.jobboardProducts;
  const [advertPosting, {isLoading:postingLoading}] = useAdvertPostingMutation();
  const { data: postingDetails,isLoading : postingDetailsLoading,  refetch: refetchPostingDetail } = useGetPostingDetailsQuery({
    params: {
      campaignId: campaignId,
    },
    postingId
  }, { skip: !campaignId || !postingId });

  const refId = postingDetails?.campaignGeneralInfo?.jobPostings[0]?.bbRef;
  const { data: jobBoardsSummaryDetails, refetch: refetchSummaryDetail } = useGetJobBoardSummaryQuery({ refId }, { skip: !refId,
    refetchOnMountOrArgChange:true,
   });



  const checkBoardExist=(accessKey)=>
    jobBoardKeys?.some(
      (item) => item?.jobBoard?.broadbeanShortcode
        === accessKey && item?.posterAccessKey === 'OCCY'
    )

  const showMonsterEditFormKey = checkBoardExist(jobBoardAccessKeys.MONSTER)
  const cvlibraryKey = checkBoardExist(jobBoardAccessKeys.CV_LIBRARY);
  const reedKey = checkBoardExist(jobBoardAccessKeys.REED);
  const totalJobKey = checkBoardExist(jobBoardAccessKeys.TOTAL_JOBS);

  useEffect(() => {
    if (status?.status === "statusCheck") {
      setActiveTab(1)
    } else {
      setActiveTab(0)
    }
  }, [status?.status]);

  // const jobCategoryTitle = postingDetails?.campaignAdvert?.jobCategory?.title;
  // const matchingIndustry = industries.find(industry => industry.value === jobCategoryTitle);

  const validationSchema = Yup.object().shape({
    additionalIndustry: Yup.object().when('cvlibraryKey', {
      is: true,
      then: Yup.object().required('Additional Industry is required'),
    }),

    // quickapply: Yup.object().when('cvlibraryKey', {
    //   is: true,
    //   then: Yup.object().required('Required'),
    // }),

    product: Yup.object().when('reedKey', {
      is: true,
      then: Yup.object().required('Required'),
    }),

    expiryInDays: Yup.number().when('reedKey', {
      is: true,
      then: Yup.number()
        .max(42, 'Expiry In Days should not be more than 42')
        .required('Expiry In Days is required'),
    }),

    sendApplicationDigest: Yup.object().when('reedKey', {
      is: true,
      then: Yup.object().required('Required'),
    }),

    parentSector: Yup.object().when('reedKey', {
      is: true,
      then: Yup.object().required('Required'),
    }),

    coverLetterPreference: Yup.object().when('reedKey', {
      is: true,
      then: Yup.object().required('Required'),
    }),

    isPublic: Yup.object().when('reedKey', {
      is: true,
      then: Yup.object().required('Required'),
    }),

    salaryOTE: Yup.object().when('reedKey', {
      is: true,
      then: Yup.object().required('Required'),
    }),

    isGraduate: Yup.object().when('reedKey', {
      is: true,
      then: Yup.object().required('Required'),
    }),

    eligibleUkOnly: Yup.object().when('reedKey', {
      is: true,
      then: Yup.object().required('Required'),
    }),

    inventoryType: Yup.object().when('showMonsterEditFormKey', {
      is: true,
      then: Yup.object().required('Required'),
    }),

    jobLevel: Yup.object().when('showMonsterEditFormKey', {
      is: true,
      then: Yup.object().required('Required'),
    }),

    jobType: Yup.object().when('showMonsterEditFormKey', {
      is: true,
      then: Yup.object().required('Required'),
    }),

    desiredDuration:Yup.object().when('showMonsterEditFormKey', {
      is: true,
      then:Yup.object().required('Required'),
    }),

    industryName: Yup.object().when('showMonsterEditFormKey', {
      is: true,
      then: Yup.object().required('Required'),
    }),

    liveDays: Yup.number().when('totalJobKey', {
      is: true,
      then: Yup.number().required('Live days is required').min(1),
    }),

    rateUnit: Yup.object().when('totalJobKey', {
      is: true,
      then: Yup.object().required('Rate Unit is required'),
    }),

    euOnly: Yup.object().when('totalJobKey', {
      is: true,
      then: Yup.object().required('Required'),
    }),
  });
  
  const findCVLibraryObject = (jobBoardPostings, accessKey) => {
    if (jobBoardPostings && jobBoardPostings?.jobBoardMeta) {
      const jobBoardPost=jobBoardPostings?.jobBoardMeta
      for (const key in jobBoardPost) {
        const metaObject = jobBoardPost[key];
        if (metaObject?.accessKey === accessKey) {
          return metaObject;
        }
      }
    }
    return false;
  }

  const cvLibraryObject = findCVLibraryObject(postingDetails?.campaignGeneralInfo?.jobPostings[0]?.jobBoardPosting, jobBoardAccessKeys?.CV_LIBRARY);
  const reedObject = findCVLibraryObject(postingDetails?.campaignGeneralInfo?.jobPostings[0]?.jobBoardPosting, jobBoardAccessKeys?.REED);
  const monsterObject = findCVLibraryObject(postingDetails?.campaignGeneralInfo?.jobPostings[0]?.jobBoardPosting, jobBoardAccessKeys?.MONSTER);
  const nhsObject = findCVLibraryObject(postingDetails?.campaignGeneralInfo?.jobPostings[0]?.jobBoardPosting, jobBoardAccessKeys?.NHS);
  const totalObject = findCVLibraryObject(postingDetails?.campaignGeneralInfo?.jobPostings[0]?.jobBoardPosting, jobBoardAccessKeys?.TOTAL_JOBS);
  const gumTreeObject = findCVLibraryObject(postingDetails?.campaignGeneralInfo?.jobPostings[0]?.jobBoardPosting, jobBoardAccessKeys?.GUM_TREE);
  const jobBoardSummaryKeys = { cvLibraryObject, reedObject, monsterObject, nhsObject, totalObject, gumTreeObject }

  const handleSubmit = (values) => {
    const payload = {
      jpId: values?.campaignGeneralInfo?.jobPostings[0]?.id,
      campaignId: values?.campaignGeneralInfo?.jobPostings[0]?.campaignId,
      additionalInfo: []
    }
    if (values?.cvlibraryKey) {
      payload.additionalInfo.push({
        accessKey: jobBoardAccessKeys.CV_LIBRARY,
        additionalIndustry: values?.additionalIndustry?.value,
        // quickApply: values?.quickapply?.value,
        region: ""
      });
    }

    if (values?.reedKey) {
      payload.additionalInfo.push({
        accessKey: jobBoardAccessKeys.REED,
        product: values?.product?.value,
        expiryInDays: values?.expiryInDays,
        isPublic: values?.isPublic?.value,
        isGraduate: values?.isGraduate?.value,
        sendApplicationDigest: values?.sendApplicationDigest?.value,
        parentSector: values?.parentSector?.value,
        jobSector: values?.jobSector?.value,
        salaryOTE: values?.salaryOTE?.value,
        eligibleUkOnly: values?.eligibleUkOnly?.value,
        coverLetterPreference: values?.coverLetterPreference?.value,
        region: ""
      });
    }

    if (values?.totalJobKey) {
      payload.additionalInfo.push({
        accessKey: jobBoardAccessKeys.TOTAL_JOBS,
        liveDays: values?.liveDays,
        rateUnit: values?.rateUnit?.value,
        euOnly: values?.euOnly?.value
      });
    }
    if (values?.showMonsterEditFormKey) {
      payload.additionalInfo.push({
        accessKey: jobBoardAccessKeys.MONSTER,
        inventoryType: values?.inventoryType?.value,
        jobLevel: values?.jobLevel?.value,
        jobType: values?.jobType?.value,
        industryName: values?.industryName?.value,
        desiredDuration: values?.desiredDuration?.value
      });
    }

    if(values?.cvlibraryKey || values?.reedKey || values?.totalJobKey || values?.showMonsterEditFormKey)
    {  
    advertPosting({ payload })
      .unwrap()
      .then((res) => {
        let isSuccess = true
        if(res?.resp)
        {
          res?.resp?.map((item)=>{
            if(!item?.postingDetails?.success)
            {
              toast.error(item?.postingDetails?.message);
              isSuccess=false
            }
          })
          setFailedResp(res?.resp)
        }
        refetchSummaryDetail();
        setActiveTab(1);
        refetchPostingDetail();
        isSuccess && toast.success(res?.message || 'Posting Successfully!');
      });
    }
  }
const [clickNext,setClickNext]=useState(false);
  return (
    <>
      <Portlet>
        <PortletHeader
          title={
            <div className="d-flex align-items-center gap-2">
              <StyledLabel size="32px" weight="600">
                {'Advertise'}
              </StyledLabel>
            </div>
          }
        />
        <Formik
          initialValues={{
            ...postingDetails,
            additionalIndustry: cvLibraryObject ? { value: cvLibraryObject?.additionalIndustry, label: cvLibraryObject?.additionalIndustry } : { value: 'Other', label: 'Other' },
            // quickapply: cvLibraryObject ? {
            //   label: cvLibraryObject?.quickApply ? 'Yes' : 'No',
            //   value: cvLibraryObject?.quickApply ?? true,
            // } : { label: 'Yes', value: true },
            product: reedObject ? { label: reedObject?.product, value: reedObject?.product } : '',
            expiryInDays: reedObject ? reedObject?.expiryInDays : '',
            parentSector: reedObject ? { label: reedObject?.parentSector, value: reedObject?.parentSector } : '',
            sendApplicationDigest: reedObject ? {
              label: reedObject?.sendApplicationDigest ? 'Yes' : 'No',
              value: reedObject?.sendApplicationDigest
            } : '',
            isPublic: reedObject ? {
              label: reedObject?.isPublic ? 'Yes' : 'No',
              value: reedObject?.isPublic
            } : '',
            salaryOTE: reedObject ? {
              label: reedObject?.salaryOTE ? 'Yes' : 'No',
              value: reedObject?.salaryOTE
            } : '',
            isGraduate: reedObject ? {
              label: reedObject?.isGraduate ? 'Yes' : 'No',
              value: reedObject?.isGraduate
            } : '',
            eligibleUkOnly: reedObject ? {
              label: reedObject?.eligibleUkOnly ? 'Yes' : 'No',
              value: reedObject?.eligibleUkOnly
            } : '',
            coverLetterPreference: reedObject ? { label: reedObject?.coverLetterPreference, value: reedObject?.coverLetterPreference } : '',
            liveDays: totalObject ? totalObject?.liveDays : '',
            rateUnit: totalObject ? { label: totalObject?.rateUnit, value: totalObject?.rateUnit } : '',
            euOnly: totalObject ? {
              label: totalObject?.euOnly ? 'Yes' : 'No',
              value: totalObject?.euOnly
            } : { label: 'Yes', value: true },
            inventoryType: monsterObject ? { label: monsterObject?.inventoryType, value: monsterObject?.inventoryType } : { label: 'Transactional', value: 'Transactional' },
            jobLevel: monsterObject ? { label: monsterObject?.jobLevel, value: monsterObject?.jobLevel } : '',
            jobType: monsterObject ? { label: monsterObject?.jobType, value: monsterObject?.jobType } : '',
            desiredDuration: monsterObject ? monsterDuration?.find(item=>item?.value === monsterObject?.desiredDuration) : '',
            industryName: monsterObject ? { label: monsterObject?.industryName, value: monsterObject?.industryName } : '',
            showMonsterEditFormKey: showMonsterEditFormKey,
            reedKey: reedKey,
            cvlibraryKey: cvlibraryKey,
            totalJobKey: totalJobKey,
            jobBoardSummaryKeys: jobBoardSummaryKeys,
            jobBoardsSummaryDetails:jobBoardsSummaryDetails
          }}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ handleSubmit, setFieldValue, values, errors, ...props }) => (
            <Form className='w-100'>
              <div className="d-flex bg-body">
                <TabsSidebar
                  sidebar={sidebar}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  values={values}
                  jobBoardsSummaryDetails={jobBoardsSummaryDetails}
                  handleSubmit={handleSubmit}
                  isBroadBeanProduct={jobBoardKeys?.some((item) =>item?.posterAccessKey === 'BB' )}
                  validationErrors={errors}
                  isLoading={postingLoading}
                />

                <div className='w-100'>
                  {activeTab === 0 && (
                    // postingDetails &&
                    <div className="portlet__body-bg w-100">
                      <Wrap className="justify-content-center my-5">
                        <AdvertPostingDetails
                          showMonsterEditFormKey={showMonsterEditFormKey}
                          cvlibraryKey={cvlibraryKey}
                          totalJobKey={totalJobKey}
                          reedKey={reedKey}
                          values={values}
                          setClickNext={setClickNext}
                          activeTab={activeTab}
                          setFieldValue={setFieldValue}
                          setActiveTab={setActiveTab}
                          postingLoading={postingLoading}
                          isBroadBeanProduct={jobBoardKeys?.some((item) =>item?.posterAccessKey === 'BB' )}
                          jobBoardsSummaryDetails={jobBoardsSummaryDetails} />
                         
                      </Wrap>
                    </div>
                  )}
                  {activeTab === 1 && (
                    // postingDetails &&
                    <div className="portlet__body-bg w-100">
                      <Wrap className="d-flex mb-3 justify-content-center">
                        <JobBoardSummary jobBoardsSummaryDetails={jobBoardsSummaryDetails} refetchSummaryDetail={refetchSummaryDetail} postingDetails={postingDetails} setFieldValue={setFieldValue} refId={refId} values={values} handleSubmit={handleSubmit} jobBoardKeys={jobBoardKeys}  postingId={postingId} clickNext={clickNext} campaginRefetch={campaginRefetch} postingDetailsLoading={postingDetailsLoading} failedResp={failedResp}/>
                      </Wrap>
                    </div>
                  )}
                </div>

              </div>
            </Form>)}</Formik>
      </Portlet>
    </>
  );
};

export default CampaignPostingDetailPage;
